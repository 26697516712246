<template>
  <div class="row">
    <div class="col-12 g c col-lg-7">
      <div class="card">
        <div class="card-header text-center">
          <h4>
            <i class="fa fa-user-edit"></i>
            تعديل الطالب
          </h4>
        </div>
        <div class="card-body">
          <div class="col-12 text-center">
            <img
              :src="
                image ? image : require('@/assets/images/avatars/avatar2.png')
              "
              style="
                width: 100px;
                height: 100px;
                cursor: pointer;
                object-fit: cover;
                border-radius: 50%;
              "
            />
            <div id="progress-wrp">
              <div class="progress-bar"></div>
              <div class="status">0%</div>
            </div>
            <br />
            <input
              type="file"
              title="اضغط لتغيير الصورة"
              id="ingredient_file"
            />
          </div>
          <div class="form-group">
            <h5 for="">الإسم</h5>
            <input type="text" class="form-control" v-model="name" />
          </div>
          <div class="form-group">
            <h5 for="">رقم الهوية</h5>
            <input type="text" class="form-control" v-model="number" />
          </div>
          <div class="form-group" v-if="parent_id != null">
            <h5 for="">
              ولي الأمر
              <a href="/parents/add" target="_blank"><u>إضافة ولي أمر</u></a>
            </h5>
            <b-form-group>
              <v-select
                v-on:input="phonea()"
                v-model="parent_id"
                :options="parents"
                :dir="'rtl'"
                label="name"
              />
            </b-form-group>
          </div>
          <div class="form-group">
            <h5 for="">الجوال</h5>
            <input type="text" class="form-control" v-model="phone" />
          </div>
          <div class="form-group">
            <h5 for="">جوال آخر للضرورة</h5>
            <input type="text" class="form-control" v-model="another_phone" />
          </div>
          <div class="form-group">
            <h5 for="">
              الصف
              <button class="btn btn-sm btn-link" @click="manual = true">
                كتابة يدوياً
              </button>
            </h5>
            <select
              class="form-control"
              name=""
              v-if="!manual"
              v-model="classnamea"
              id=""
            >
              <option :value="null">-- إختر --</option>
              <template v-for="classname in classes">
                <option :value="classname" :key="classname">
                  {{ classname }}
                </option>
              </template>
            </select>
            <input
              type="text"
              class="form-control"
              v-if="manual"
              v-model="classnamea"
              name=""
              id=""
            />
          </div>
          <div class="form-group">
            <h5 for="">الفصل</h5>
            <select
              class="form-control"
              name=""
              v-if="!manual"
              v-model="classrooma"
              id=""
            >
              <option :value="null">-- إختر --</option>
              <template v-for="classroom in classrooms">
                <option :value="classroom" :key="classroom">
                  {{ classroom }}
                </option>
              </template>
            </select>
            <input
              type="text"
              class="form-control"
              v-if="manual"
              v-model="classrooma"
              name=""
              id=""
            />
          </div>
          <div class="form-group">
            <h5 for="">خط السير</h5>
            <select class="form-control" name="" v-model="group_id" id="">
              <option :value="null">-- إختر --</option>
              <template v-for="group in groups">
                <option :value="group._id" :key="group._id">
                  {{ group.title }}
                </option>
              </template>
            </select>
          </div>
          <div class="form-group">
            <h5 for="">النوع</h5>
            <select class="form-control" v-model="type" name="" id="">
              <option value="all">ذهاب وعودة</option>
              <option value="go">ذهاب</option>
              <option value="back">عودة</option>
            </select>
          </div>
          <div class="form-group">
            <h5 for="">القسم</h5>
            <select class="form-control" name="" v-model="section_id" id="">
              <template v-for="section in sections">
                <option :value="section._id" :key="section._id">
                  {{ section.title }}
                </option>
              </template>
            </select>
          </div>
          <div class="form-group">
            <h5 for="">ملاحظات</h5>
            <textarea
              class="form-control"
              name=""
              id=""
              v-model="notes"
              rows="3"
            ></textarea>
          </div>
          <div class="col-12 text-center">
            <button class="btn btn-relief-success" @click="add()">
              تعديل الطالب <i class="fa fa-arrow-left"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-5 g" v-if="lat_">
      <div class="col-12 d-none d-lg-block">
        <br /><br /><br />
        <br /><br /><br />
        <br /><br /><br />
      </div>
      <div class="card card-body">
        <div class="col-12 text-center g" v-if="nothing">
          <h4>لا يوجد اي خط سير متاح</h4>
        </div>
        <div class="col-12 text-center g" v-if="!viewmap && !nothing">
          <img :src="require('@/assets/images/loading.svg')" alt="" />
          <h4>جاري البحث عن اقرب خط سير..</h4>
        </div>
        <div v-if="viewmap">
          <l-map :zoom="zoom" :center="[lat_, long_]">
            <l-tile-layer :url="url" />
            <l-polyline
              :lat-lngs="polyline.latlngs"
              :color="polyline.color"
            ></l-polyline>
            <l-polyline
              :lat-lngs="polyline.latlngs2"
              :color="polyline.color2"
            ></l-polyline>
            <l-marker :lat-lng="[lat_, long_]">
              <l-icon>
                <b-img
                  style="
                    border: dashed 2px red;
                    border-radius: 50%;
                    padding: 5px;
                    object-fit: cover;
                  "
                  :src="
                    image
                      ? image
                      : require('@/assets/images/avatars/avatar2.png')
                  "
                  height="50"
                  width="50"
                />
              </l-icon>
            </l-marker>
            <l-marker
              v-for="(point, index) in points"
              :key="point.lat"
              :lat-lng="[point.lat, point.long]"
            >
              <l-icon>
                <v-popup content="البداية"></v-popup>
                <b-img
                  v-if="index == 0"
                  :src="require('@/assets/images/start.png')"
                  height="50"
                />
                <b-img
                  v-if="index == points.length - 1 && ppoint != point.lat"
                  :src="require('@/assets/images/end.png')"
                  height="50"
                />
                <b-img
                  v-if="
                    index != points.length - 1 &&
                    ppoint != point.lat &&
                    index != 0
                  "
                  :src="require('@/assets/images/bus.png')"
                  height="50"
                />
                <b-img
                  v-if="ppoint == point.lat"
                  style="
                    border: dashed 2px red;
                    border-radius: 50%;
                    padding: 5px;
                  "
                  :src="require('@/assets/images/ppoint.png')"
                  height="50"
                />
              </l-icon>
            </l-marker>
          </l-map>
          <br />
          <div class="col-12 text-center">
            <span class="badge g c text-success"
              >أقرب نقطة توقف: <u>{{ distance }} كم</u></span
            >
            <br />
            خط السير المُقترح: <u>{{ group_title }}</u>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { LMap, LTileLayer, LMarker, LIcon, LPolyline } from "vue2-leaflet";
import { latLng, icon, Icon } from "leaflet";
import { BImg, BFormGroup } from "bootstrap-vue";
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
import vSelect from "vue-select";
export default {
  components: {
    BImg,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPolyline,
    vSelect,
    BFormGroup,
  },
  data() {
    return {
      sections: [],
      section_id: null,
      zoom: 13,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      ppoint: null,
      points: [],
      polyline: {
        latlngs: [],
        latlngs2: [],
        color: "green",
        color2: "red",
      },
      icon: icon({
        iconUrl: require("@/assets/images/bus.png"),
        iconSize: [50, 50],
        iconAnchor: [16, 37],
      }),
      //////
      manual: false,
      distance: 0,
      viewmap: false,
      group_title: null,
      image: null,
      type: null,
      nothing: false,
      name: null,
      parents: [],
      parent_id: null,
      notes: null,
      classrooma: null,
      phone: null,
      number: null,
      classnamea: null,
      ppoint_: {},
      another_phone: null,
      classrooms: [],
      pasteinterval: null,
      lat_: null,
      long_: null,
      classes: [],
      group_id: null,
      user: JSON.parse(localStorage.getItem("user")),
      groups: [],
    };
  },
  created() {
    checkPer(this, "students", "edit");
    var g = this;
    var last_point = null;
    g.pasteinterval = setInterval(function () {
      if (document.activeElement) {
        navigator.clipboard.readText().then((text) => {
          if (text.includes(",") && text.includes(".") && !text.includes("/")) {
            if (text != last_point) {
              if (confirm("هل تريد اضافة الاحداثيات المنسوخة؟")) {
                g.detectPoint(text);
                last_point = text;
              }
            }
          }
        });
      }
    }, 500);
    $.post(api + "/admin/groups/list", {
      jwt: g.user.jwt,
    }).then(function (r) {
      g.groups = JSON.parse(r).response;
    });
    $.post(api + "/admin/parents/list", {
      jwt: g.user.jwt,
    }).then(function (r) {
      g.parents = JSON.parse(r).response;
      $.post(api + "/admin/students/student", {
        jwt: g.user.jwt,
        id: window.location.href.split("/edit/")[1],
      }).then(function (r) {
        var a = JSON.parse(r).response;
        g.name = a.name;
        g.number = a.number;
        g.parent_id = g.getParent(a.parent_id);
        g.phone = a.phone;
        g.another_phone = a.another_phone;
        g.classnamea = a.classname;
        g.classrooma = a.classroom;
        g.group_id = a.group_id;
        g.notes = a.notes;
        g.image = a.image;
        g.section_id = a.section_id;
        g.type = a.type;
        g.detectPoint(a.location.lat + "," + a.location.long);
      });
    });
    $.post(api + "/admin/students/classes", {
      jwt: g.user.jwt,
    }).then(function (r) {
      g.classes = JSON.parse(r).response.classes;
      g.classrooms = JSON.parse(r).response.classrooms;
    });
    var g = this;
    var Upload = function (file) {
      this.file = file;
    };

    Upload.prototype.getType = function () {
      return this.file.type;
    };
    Upload.prototype.getSize = function () {
      return this.file.size;
    };
    Upload.prototype.getName = function () {
      return this.file.name;
    };
    Upload.prototype.doUpload = function () {
      var that = this;
      var formData = new FormData();

      // add assoc key values, this will be posts values
      formData.append("file", this.file, this.getName());
      formData.append("upload_file", true);
      $.ajax({
        type: "POST",
        url: api + "/admin/students/upload-avatar",
        xhr: function () {
          var myXhr = $.ajaxSettings.xhr();
          if (myXhr.upload) {
            myXhr.upload.addEventListener(
              "progress",
              that.progressHandling,
              false
            );
          }
          return myXhr;
        },
        success: function (data) {
          $("#progress-wrp").fadeOut("slow");
          g.image = data.response;
        },
        error: function (error) {
          // handle error
          alert("حدث خطأ");
        },
        async: true,
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        timeout: 60000,
      });
    };

    Upload.prototype.progressHandling = function (event) {
      var percent = 0;
      var position = event.loaded || event.position;
      var total = event.total;
      var progress_bar_id = "#progress-wrp";
      if (event.lengthComputable) {
        percent = Math.ceil((position / total) * 100);
      }
      // update progressbars classes so it fits your code
      $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
      $(progress_bar_id + " .status").text(percent + "%");
    };
    setTimeout(() => {
      $("#ingredient_file").on("change", function (e) {
        $("#progress-wrp").fadeIn("slow");
        var file = $(this)[0].files[0];
        var upload = new Upload(file);

        // maby check size or type here with upload.getSize() and upload.getType()

        // execute upload
        upload.doUpload();
      });
    }, 1000);
    $.post(api + "/admin/sections/list", {
      jwt: g.user.jwt,
    }).then(function (r) {
      g.sections = JSON.parse(r).response;
    });
  },
  beforeDestroy() {
    clearInterval(this.pasteinterval);
  },
  methods: {
    phonea() {
      this.phone = this.parent_id.phone;
    },
    getParent(id) {
      var p = {};
      this.parents.forEach(function (r) {
        if (r._id == id) {
          p = r;
        }
      });
      return p;
    },
    detectPoint(point) {
      var g = this;
      g.nothing = false;
      g.viewmap = false;
      g.loading = true;
      g.$toast({
        component: ToastificationContent,
        props: {
          title: "تم لصق الاحداثيات",
          icon: "CopyIcon",
          variant: "info",
        },
      });
      var audio = new Audio(
        "https://cdn-static.brmja.com/storage/uploads/files/620511d8d5536534353593.mp3"
      );
      audio.play();
      g.lat_ = point.split(",")[0].trim();
      g.long_ = point.split(",")[1].trim();
      $.post(api + "/admin/students/nearset-group", {
        jwt: g.user.jwt,
        lat: g.lat_,
        long: g.long_,
      })
        .then(function (r) {
          r = JSON.parse(r);
          if (r.group == "nothing") {
            g.nothing = true;
            g.loading = false;
            g.viewmap = false;
          } else {
            g.points = r.group.points;
            g.ppoint = r.point.lat;
            g.ppoint_ = r.point;
            g.points.forEach((element) => {
              g.polyline.latlngs.push([element.lat, element.long]);
            });
            g.distance = r.distance.toFixed(2);
            g.polyline.latlngs2.push([g.lat_, g.long_]);
            g.polyline.latlngs2.push([r.point.lat, r.point.long]);
            g.group_title = r.group.title;
            g.nothing = false;
            g.loading = false;
            g.viewmap = true;
          }
        })
        .catch(function () {
          alert("حدث خطأ");
          g.lat_ = null;
        });
    },
    add() {
      var g = this;
      if (!g.parent_id || !g.name || !g.number) {
        alert(
          `هناك حقول مطلوبة\nبرجاء التأكد من الحقول التالية: \nالاسم\nرقم الهوية\nولي الأمر`
        );
        return 0;
      }
      $.post(api + "/admin/students/edit", {
        jwt: g.user.jwt,
        name: g.name,
        image: g.image,
        notes: g.notes,
        phone: g.phone,
        id: window.location.href.split("/edit/")[1],
        another_phone: g.another_phone,
        classname: g.classnamea,
        classroom: g.classrooma,
        number: g.number,
        group_id: g.group_id,
        section_id: g.section_id,
        type: g.type,
        stop_location: {
          lat: g.ppoint_.lat,
          long: g.ppoint_.long,
        },
        location: {
          lat: g.lat_,
          long: g.long_,
        },
        parent_id: g.parent_id._id,
      })
        .then(function (e) {
          e = JSON.parse(e);
          if (e.status != 100) {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: e.response,
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          } else {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "تم تعديل الطالب بنجاح",
                icon: "CheckIcon",
                variant: "success",
              },
            });
            g.details = null;
            g.title = null;
            g.group_id = null;
          }
        })
        .catch(function () {
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ",
              icon: "TimesIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>
<style scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.5/leaflet.css";
#progress-wrp {
  border: 1px solid #0099cc;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
  text-align: left;
  background: #fff;
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
  display: none;
}

#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: greenyellow;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #000000;
}
</style><style lang="scss">
.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}
</style>